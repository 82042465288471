export default {
  apples: ["/assets/products/apples/1.jpeg", "/assets/products/apples/2.jpeg"],
  blackberries: [
    "/assets/products/blackberries/1.jpeg",
    "/assets/products/blackberries/2.jpeg",
    "/assets/products/blackberries/3.jpeg",
    "/assets/products/blackberries/4.jpeg"
  ],
  cherries: [
    "/assets/products/cherries/1.jpeg",
    "/assets/products/cherries/2.jpeg",
    "/assets/products/cherries/3.jpeg",
    "/assets/products/cherries/4.jpeg"
  ],
  cucumbers: [
    "/assets/products/cucumbers/1.jpeg",
    "/assets/products/cucumbers/2.jpeg",
    "/assets/products/cucumbers/3.jpeg",
    "/assets/products/cucumbers/4.jpeg"
  ],

  eggplants: [
    "/assets/products/eggplants/1.jpeg",
    "/assets/products/eggplants/2.jpeg",
    "/assets/products/eggplants/3.jpeg",
    "/assets/products/eggplants/4.jpeg",
    "/assets/products/eggplants/5.jpeg",
    "/assets/products/eggplants/6.jpeg"
  ],

  grapes: ["/assets/products/grapes/1.jpeg"],

  peas: [
    "/assets/products/peas/1.jpeg",
    "/assets/products/peas/2.jpeg",
    "/assets/products/peas/3.jpeg"
  ],

  lettuces: [
    "/assets/products/lettuces/1.jpeg",
    "/assets/products/lettuces/2.jpeg",
    "/assets/products/lettuces/3.jpeg",
    "/assets/products/lettuces/4.jpeg"
  ],

  melons: [
    "/assets/products/melons/1.jpeg",
    "/assets/products/melons/2.jpeg",
    "/assets/products/melons/3.jpeg",
    "/assets/products/melons/4.jpeg",
    "/assets/products/melons/5.jpeg",
    "/assets/products/melons/6.jpeg"
  ],

  peppers: [
    "/assets/products/peppers/2.jpeg",
    "/assets/products/peppers/4.jpeg",
    "/assets/products/peppers/6.jpeg",
    "/assets/products/peppers/7.jpeg",
    "/assets/products/peppers/8.jpeg",
    "/assets/products/peppers/a.jpeg",
    "/assets/products/peppers/b.jpeg"
  ],

  pumpkins: [
    "/assets/products/pumpkins/1.jpeg",
    "/assets/products/pumpkins/2.jpeg",
    "/assets/products/pumpkins/3.jpeg"
  ],

  redberries: ["/assets/products/redberries/1.png"],

  strawberries: [
    "/assets/products/strawberries/1.jpeg",
    "/assets/products/strawberries/2.jpeg",
    "/assets/products/strawberries/3.jpeg",
    "/assets/products/strawberries/4.jpeg",
    "/assets/products/strawberries/5.jpeg"
  ],

  tangerines: [
    "/assets/products/tangerines/1.jpeg",
    "/assets/products/tangerines/2.jpeg",
    "/assets/products/tangerines/3.jpeg",
    "/assets/products/tangerines/4.jpeg",
    "/assets/products/tangerines/5.jpeg",
    "/assets/products/tangerines/6.jpeg"
  ],

  tomatoes: [
    "/assets/products/tomatoes/1.jpeg",
    "/assets/products/tomatoes/2.jpeg",
    "/assets/products/tomatoes/3.jpeg",
    "/assets/products/tomatoes/4.jpeg",
    "/assets/products/tomatoes/5.jpeg",
    "/assets/products/tomatoes/6.jpeg",
    "/assets/products/tomatoes/7.jpeg",
    "/assets/products/tomatoes/9.jpeg",
    "/assets/products/tomatoes/a.jpeg",
    "/assets/products/tomatoes/b.jpeg"
  ],

  watermelons: [
    "/assets/products/watermelons/1.png",
    "/assets/products/watermelons/3.png"
  ],

  yellowbeans: [
    "/assets/products/yellowbeans/1.jpeg",
    "/assets/products/yellowbeans/2.jpeg",
    "/assets/products/yellowbeans/3.jpeg",
    "/assets/products/yellowbeans/4.jpeg"
  ],

  olives: [
    "/assets/products/olives/1.jpeg",
    "/assets/products/olives/2.jpeg",
    "/assets/products/olives/3.jpeg",
    "/assets/products/olives/4.jpeg",
    "/assets/products/olives/5.jpeg",
    "/assets/products/olives/6.jpeg",
    "/assets/products/olives/7.jpeg",
    "/assets/products/olives/9.jpeg"
  ],
};
