import React, { useState } from "react";
import { useSelector } from "react-redux";
import { productsLang, productsWidth } from "../constants/products";
import Slider from "react-slick";
import sliderImages from "../constants/products-slider-images";

const ProductsSection = () => {
  const [product, setProduct] = useState("peppers");
  const lang = useSelector(store => store.language);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const availableProducts = Object.keys(productsWidth);

  return (
    <div id="products">
      <h1>{productsLang.mainHeader[lang]}</h1>
      <p>{productsLang.subHeader[lang]}</p>

      <div className="flex-center">
        <div className="wrapper">
          <button>
            {productsLang[product][lang]} <i className="fas fa-caret-down"></i>
          </button>
          <ul className="submenu">
            {availableProducts.map(product => (
              <li
                key={product}
                onClick={() => {
                  setProduct(product);
                }}
              >
                <button>{productsLang[product][lang]}</button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <Slider
        className="slider"
        {...settings}
        style={{ maxWidth: productsWidth[product] }}
      >
        {sliderImages[product].map((path, ind) => (
          <img src={path} key={ind} alt={`${product} number ${ind}`} />
        ))}
      </Slider>

      {/*
      <h2 className="">{productsLang.h2[lang]}</h2>

      <div className="months">
        {months.map((month, ind) => {
          return (
            <div
              key={ind}
              className={
                availability[product][ind]
                  ? "month available"
                  : "month notAvailable"
              }
            >
              <span className="straight">{month}</span>
            </div>
          );
        })}
      </div>
      */}
    </div>
  );
};

export default ProductsSection;
