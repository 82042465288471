import React from "react";
import { useSelector } from "react-redux";
import about from "../constants/about";

const AboutSection = () => {
  const lang = useSelector(store => store.language);

  return (
    <div id="about" className="about">
      <div className="about-content-wrapper">
        <div className="text-left margin-bottom-lg">
          <h2 className="main-heading">{about.mainHeader[lang]}</h2>
        </div>

        <div className="row">
          <div className="col-1-of-2">
            <div className="composition">
              <img
                sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                alt="Pic 1"
                className="composition__pics composition__pics--pic1"
                src="/assets/about/1.jpeg"
              />

              <img
                sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                alt="Pic 2"
                className="composition__pics composition__pics--pic2"
                src="/assets/about/2.jpeg"
              />

              <img
                sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                alt="Pic 3"
                className="composition__pics composition__pics--pic3"
                src="/assets/about/3.jpeg"
              />

              <img
                sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                alt="Pic 2"
                className="composition__pics composition__pics--pic4"
                src="/assets/about/4.jpeg"
              />

              <img
                sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
                alt="Pic 3"
                className="composition__pics composition__pics--pic5"
                src="/assets/about/5.jpeg"
              />
            </div>
          </div>
          <div className="col-1-of-2 text-center">
            <h3 className="paragraph-header margin-bottom-sm">
              {about.paragraphHeader[lang]}
            </h3>
            <p className="paragraph">{about.firstParagraph[lang]}</p>

            <p className="paragraph">{about.secondParagraph[lang]}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
