const productsLang = {
  mainHeader: {
    en: "Our Products",
    ita: "I nostri prodotti",
    de: "Unsere Produkte"
  },
  subHeader: {
    en: "Here are some of the products we export.",
    ita: "Ecco alcuni dei prodotti che esportiamo.",
    de: "Hier sind einige der Produkte, die wir exportieren."
  },
  peppers: {
    en: "Peppers",
    ita: "Peperoni",
    de: "Paprika"
  },
  blackberries: {
    en: "Black Berries",
    ita: "Bacche nere",
    de: "Schwarze Beeren"
  },
  redberries: {
    en: "Red Berries",
    ita: "Bacche rosse",
    de: "Rote Beeren"
  },
  tomatoes: {
    en: "Tomatoes",
    ita: "Pomodori",
    de: "Tomaten"
  },
  leeks: {
    en: "Leeks",
    ita: "Porri",
    de: "Lauch"
  },
  pumpkins: {
    en: "Pumpkins",
    ita: "Zucche",
    de: "Kürbisse"
  },
  olives: {
    en: "Olives",
    ita: "Olive",
    de: "Oliven"
  },
  strawberries: {
    en: "Strawberries",
    ita: "Fragole",
    de: "Erdbeeren"
  },
  cherries: {
    en: "Cherries",
    ita: "Ciliegie",
    de: "Kirschen"
  },
  watermelons: {
    en: "Watermelons",
    ita: "Angurie",
    de: "Wassermelonen"
  },
  tangerines: {
    en: "Tangerines",
    ita: "Mandarini",
    de: "Mandarinen"
  },
  peas: {
    en: "Peas",
    ita: "Piselli",
    de: "Erbsen"
  },
  yellowbeans: {
    en: "Yellow Beans",
    ita: "Fagioli gialli",
    de: "Gelbe Bohnen"
  },
  cucumbers: {
    en: "Cucumbers",
    ita: "Cetrioli",
    de: "Gurken"
  },
  lettuces: {
    en: "Lettuces",
    ita: "Lattughe",
    de: "Salate"
  },
  apples: {
    en: "Apples",
    ita: "Melle",
    de: "Äpfel"
  },
  eggplants: {
    en: "Eggplants",
    ita: "Melanzane",
    de: "Auberginen"
  },
  grapes: {
    en: "Grapes",
    ita: "Uva",
    de: "Trauben"
  },
  melons: {
    en: "Melons",
    ita: "Meloni",
    de: "Melonen"
  },
  months: {
    en: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    ita: [
      "Gen",
      "Feb",
      "Mar",
      "Apr",
      "Mag",
      "Giu",
      "Lug",
      "Ago",
      "Set",
      "Ott",
      "Nov",
      "Dic"
    ],
    de: [
      "Jan",
      "Feb",
      "Mär",
      "Apr",
      "Mai",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dez"
    ]
  },
  h2: {
    en: "Availability",
    ita: "Disponibilità",
    de: "Verfügbarkeit"
  }
};

const productsWidth = {
  peppers: 500,
  melons: 500,
  tomatoes: 500,
  blackberries: 1000,
  cherries: 1000,
  strawberries: 500,
  pumpkins: 1000,
  lettuces: 500,
  apples: 1000,
  grapes: 1000,
  yellowbeans: 500,
  peas: 500,
  watermelons: 1000,
  tangerines: 500,
  olives: 500,
  cucumbers: 500,
  redberries: 1000,
  eggplants: 500
};

export { productsLang, productsWidth };
